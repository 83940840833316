.connectPageContainer {
    margin: 2.5%;
}

.pageContainer {
    margin: 5% 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connectHeaderText {
    text-shadow: 3px 4px 6px #403b42;
    text-align: center;
    font-size: 300%;
}

@media screen and (max-width: 1200px) {
    .pageContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .connectPageContainer {
        margin: 10% 0 10% 0;
    }
}