.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 300px;
    z-index: 998;
    background: #202020;
    box-shadow: 0 0 12px 12px #bbbbbb;
}