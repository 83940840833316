.faqsContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0 0 10% 0;
    border: 1px solid black;
    border-radius: 3%;
    overflow: hidden;
    box-shadow: 0 0 12px 6px #bbbbbb;
}

.faqHeader {
    font-size: 300%;
}

@media screen and (max-width: 1200px) {
    .faqsContainer {
        width: 100vw;
        border: none;
        border-radius: 0;
        margin-top: 10%;
    }
}