.servicesSectionContainer {
    margin: 2.5% 0 10% 0;
}

.servicesCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin: 5%;
}

.servicesHeaderText {
    text-shadow: 3px 4px 6px #403b42;
    text-align: center;
    font-size: 300%;
}