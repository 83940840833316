.heroContainer {
    background-image: url("../../../../Assets/Pictures/HeroImage/heroHome2.avif");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 425px) {
    
}