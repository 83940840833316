.bridalMakeupContainer {
    margin: 2.5% 0 2.5% 0;
    padding-top: 2.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #cab3ee;
    box-shadow: 0 0 12px 12px #bbbbbb;
    border-radius: 10px;
    color: #000;
    font-size: 120%;
    width: 90vw;
}

.mustHave {
    font-size: 70%;
}

.servicesCarousel {
    box-shadow: 12px 10px 10px 0px #403b42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    width: 500px;
    height: 500px;
    margin: 5% 0 10% 0;
}

.bridalImg {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.bridalListContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.bridalOptionsContainer {
    padding: 2.5%;
    width: 40%;
}

.optionsContainer {
    padding: 2%;
}

/* .optionsContainer:nth-child(odd) {
    box-shadow: 7px 0px 10px 0px #403b42;
    border: 1px white solid;
} */

/* .optionsContainer:nth-child(even) {
    box-shadow: 7px 0px 10px 0px #403b42;
    background-color: #23bcf3c3;
} */

@media screen and (max-width: 775px) {
    .bridalMakeupContainer {
        border-radius: 0;
        flex-direction: column;
        width: 100vw;
        margin: 5% 0 5% 0;
    }
    .servicesCarousel {
        width: 100%;
        height: 375px;
    }
    .bridalOptionsContainer {
        padding: 0;
        width: 100%;
    }
    .optionsContainer:nth-child(even) {
        box-shadow: none;
    }
}