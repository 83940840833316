.aboutSection {
    margin: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 10% 0% 10%;
}

@media screen and (max-width: 1200px) {
    .aboutSection {
        align-items: center;
        flex-direction: column;
    }
}