.contactTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactTextHeader {
    text-shadow: 3px 4px 6px #a8a7a7;
    font-weight: bolder;
    font-size: 150%;
    text-align: center;
}

.contactText {
    text-align: center;
    font-size: 75%;
}