.servicesBox {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px;
    margin: 20px 0 20px 0;
    color: #000;
}

@media screen and (max-width: 770px) {
    .servicesBox {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}