.logoButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    height: 100%;
    margin-top: 5%;
}

@media screen and (max-width: 550px) {
    .logoButtonContainer {
        margin-top: 40%;
    }
}