.photoContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 10%;
}

@media screen and (max-width: 1200px) {
    .photoContainer {
        padding: 5% 0 5% 0;
        flex-direction: column;
        align-items: center;
    }
}