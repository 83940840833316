.serviceContainer {
    display: flex;
    flex-direction: column;
    padding: 0 7%;
    margin: 3% 0 3% 0;
    width: 50%;
}

.servicesNameHeader {
    text-shadow: 3px 4px 6px #1e1d1f;
    font-size: 150%;
    color: #000;
}

.headerLarge {
    color: #000;
    font-weight: bolder;
}

.serviceName {
    font-weight: bolder;
}

.serviceNameAlt {
    font-weight: bolder;
}

.serviceText {
    font-size: 75%;
}

@media screen and (max-width: 770px) {
    .serviceContainer {
        width: 100%;
        margin: 5% 0 5% 0;
    }
}

@media screen and (max-width: 770px) {
    .serviceNameAlt {
        margin-top: 0;
    }
}