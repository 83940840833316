.navButton {
    text-decoration: none;
    color: #fff;
    font-family: 'Urbanist',
        sans-serif;
    font-size: 150%;
}

.navButton:hover {
    color: #9c85c0;
}

/* Changes Nav Bar Font Size to 100% at >600px */
@media screen and (max-width: 600px) {
    .navButton {
        font-size: 100%;
    }
}