.contactButtonContainer {
    background-color: transparent;
}

.contactButton {
    background-color: transparent;
    color: #000;
    font-weight: bolder;
    padding: 15px;
    border: #000 solid 1px;
    border-radius: 5px;
    text-align: center;

}

.contactButton:hover {
    background-color: rgba(44, 44, 44, 0.498);
}