.aboutHeaderText {
    text-align: center;
    text-shadow: 3px 4px 6px #403b42;
    font-size: 300%;
}

.aboutTextContainer {
    padding: 1% 6% 6% 6%;
    display: flex;
    flex-direction: column;
}