* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: -webkit-fill-available;
}

body {
    max-width: 100vw;
    min-height: 100vh;
    height: -webkit-fill-available;
    background-color: #fff;
}
