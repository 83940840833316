.tutorialMakeupContainer {
    margin: 2.5% 0 2.5% 0;
    padding-top: 2.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
    background-color: #cab3ee;
    box-shadow: 0 0 12px 12px #bbbbbb;
    border-radius: 10px;
    color: #000;
    font-size: 120%;
    width: 90vw;
}

.servicesCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    width: 500px;
    height: 500px;
    margin: 5% 0 10% 0;
}

.tutorialImg {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.tutorialListContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.tutorialOptionsContainer {
    padding: 2.5%;
    width: 40%;
}

.optionsContainer {
    width: 100%;
    padding: 2%;
}

/* .optionsContainer:nth-child(even) {
    background-color: #23bcf3c3;
} */

@media screen and (max-width: 775px) {
    .tutorialMakeupContainer {
        border-radius: 0;
        flex-direction: column;
        width: 100vw;
        margin: 5% 0 5% 0;
    }

    .servicesCarousel {
        height: 375px;
        width: 100%;
    }
    .tutorialOptionsContainer {
        padding: 0;
        width: 100%;
    }
}