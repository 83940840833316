.servicesContainer {
    background-color: #cab3ee;
    box-shadow: 0 0 12px 12px #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    padding: 10% 0 10% 0;
    text-align: center;
}