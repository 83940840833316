.logoImage {
    height: 500px;
    width: 40vw;
}

@media screen and (max-width: 850px) {
    .logoImage {
        height: 200px;
        width: 90vw;
    }
}