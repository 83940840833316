.phoneContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* width: 100vw; */
}

.phoneIcon {
    transform: scale(2);
}

.phoneText {
    /* text-shadow: 3px 4px 6px #403b42; */
    font-size: 20px;
    margin: 0;
}

@media screen and (max-width: 450px) {
    .phoneText {
        font-size: 160%;
    }
    .phoneContainer {
        margin-bottom: 20%;
        width: 100vw;
    }
}