.testimonialsBox {
    box-shadow: 10px 10px 10px 0px #a8a7a7;
    margin-top: 75px;
    height: 50%;
    width: 60%;
}

@media screen and (max-width: 775px) {
    .testimonialsBox {
        width: 100%;
        box-shadow: 0 5px 10px 0px #bbbbbb;
        }
}