.navbarContainer {
    z-index: 999;
    background: #202020;
    opacity: 0.8;
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    animation: navFadeIn 5s;
}

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 10vh;
    width: 85vw;
}

@keyframes navFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.8;
    }
}

/* Changes Nav Bar width to 100vw at >600px */
@media screen and (max-width: 600px) {
    .navBar {
        width: 100vw;
    }
}