.connectContact {
    text-align: center;
    width: 90vw;
    margin: 0% 0 10% 0;
    background-color: #cab3ee;
    box-shadow: 0 0 12px 6px #bbbbbb;
    color: #000;
    border-radius: 3%;
    padding: 5%;
    font-size: 180%;
}

@media screen and (max-width: 1200px) {
    .connectContact {
        width: 100vw;
        border-radius: 0;
        margin: 10% 0 10% 0;
    }
}